import React from 'react';
import Plot from 'react-plotly.js';

const Scatterplot = ({ data }) => {
    if (!data || !data.data) {
        // console.log('Data not available');
        return <div>Loading...</div>;
    }

    const { x_opp, y_opp, texts_opp, x_pas, y_pas, texts_pas } = data.data;


    const contourData = {
        x: [...x_pas, ...x_opp],
        y: [...y_pas, ...y_opp],
        type: 'histogram2dcontour',
        colorscale: 'Reds',
        opacity: 0.4,
        contours: {
            showlabels: true,
            labelfont: {
                size: 12,
                color: 'white'
            }
        },
        showlegend: false,
        showscale: false 
    };

    const scatterOppData = {
        x: x_opp,
        y: y_opp,
        mode: 'markers',
        type: 'scatter',
        name: 'Opportunity',
        text: texts_opp.map(text => `${text.substring(0, 100)}...<br>${text.substring(101, Math.min(200, text.length))}`),
        hoverinfo: 'text+x+y',
        marker: {
            color: 'blue',
            size: 7,
            opacity: 0.5
        }
    };

    const scatterPasData = {
        x: x_pas,
        y: y_pas,
        mode: 'markers',
        type: 'scatter',
        name: 'Past Performance',
        text: texts_pas.map(text => `${text.substring(0, 100)}...<br>${text.substring(101, Math.min(200, text.length))}`),
        hoverinfo: 'text+x+y',
        marker: {
            color: 'green',
            size: 7,
            opacity: 0.5
        }
    };

    const layout = {
        hovermode: 'closest',
        xaxis: { title: '' },
        yaxis: { title: '' },
        autosize: true,
        margin: { l: 50, r: 50, b: 50, t: 50 },
        showlegend: true,
    };

    return (
        <Plot
            data={[contourData, scatterOppData, scatterPasData]}
            layout={layout}
            style={{ width: '100%', height: '100%' }}
            config={{ responsive: true, displaylogo: false }}
        />
    );
}

export default Scatterplot;
