import React, { useState, useEffect } from 'react';
import { post, isCancelError } from '@aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import './Performance.scss';
import senderImg from '../../assets/images/sender.svg';
import sendarrow from '../../assets/images/send-arrow.svg';
import { Oval } from 'react-loader-spinner';

const initialUsersData = [
    {
        senderLogo: senderImg,
        senderName: "SAM-ML",
        senderMessage: "Hello! How can I assist you today?",
        type: "sender"
    }
];

const Performance = () => {
    const [usersData, setUsersData] = useState(initialUsersData);
    const [message, setMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [companyUUID, setCompanyUUID] = useState(null);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [receiverLetter, setLetter] = useState('');


    useEffect(() => {
        async function getUserAttributes() {
            try {
                const user = await fetchUserAttributes();
                setCompanyUUID(user['custom:Company_uuidd']);
                setName(user['name']);
                setLetter(user['name'][0])
            } catch (error) {
                console.log('Error fetching user attributes', error);
            }
        }
        getUserAttributes();
    }, []);

    useEffect(() => {
        if (companyUUID) {
            setLoading(false);
        }
    }, [companyUUID]);

    const fetchRAGData = async (prompt) => {
        try {
            const fetchCompanyData = post({ 
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();

            const fetchDocData = post({ 
                apiName: 'DataFlow',
                path: '/LLM',
                options: {
                    body: {
                        process_id: uuidv4(),
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        Company_Name: companyData.name,
                        Prompt: prompt
                    },
                }
            });
            const response = await fetchDocData.response;
            const responseData = await response.body.json();
            const generatedText = responseData.data.Response;

            const documentNames = responseData.data.Metadatas
                .filter(metadata => metadata.name)
                .map(metadata => metadata.name);

            return { generatedText, documentNames };
        } catch (error) {
            if (isCancelError(error)) {
                console.log('Request cancelled');
            } else {
                console.error('Failed to fetch data:', error);
            }
        }
    };

    const handleSendMessage = async () => {
        if (message.trim() !== '') {
            const newMessage = {
                receiverLogo: receiverLetter,
                receiverName: name,
                receiverMessage: message,
                type: "receiver"
            };
            setUsersData([...usersData, newMessage]);
            setMessage('');
            setIsTyping(true);

            const response = await fetchRAGData(message);
            setIsTyping(false);
            if (response) {
                const { generatedText, documentNames } = response;
                
                const senderMessage = {
                    senderLogo: senderImg,
                    senderName: "SAM-ML",
                    senderMessage: generatedText,
                    type: "sender"
                };
                setUsersData(prevUsersData => [...prevUsersData, senderMessage]);

                if (documentNames.length > 0) {
                    const documentsMessage = {
                        senderLogo: senderImg,
                        senderName: "SAM-ML",
                        senderMessage: `Documents referenced: ${documentNames.join(', ')}`,
                        type: "sender"
                    };
                    setUsersData(prevUsersData => [...prevUsersData, documentsMessage]);
                }
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && message.trim() !== '' && !isTyping) {
            handleSendMessage();
        }
    };

    return (
        <div className="performance-screen">
            <div className="container-fluid">
                <div className="main-title">
                    <h1>Past Performance LLM</h1>
                </div>
            </div>
            {loading ? (
                <div className="loader-container">
                    <Oval color="#00BFFF" height={80} width={80} />
                </div>
            ) : (
                <div className="chat-sec">
                    <div className="chat-container">
                        <div className="chat-wrapper">
                            <div className="chat-body">
                                <div className="chat-area">
                                    {usersData.map((user, id) => (
                                        <React.Fragment key={id}>
                                            {user.type === "sender" ? (
                                                <div className="sender">
                                                    <div className="user-img">
                                                        <img src={user.senderLogo} alt="senderImg" />
                                                    </div>
                                                    <div className="user-text">
                                                        <h4 className="user-name">{user.senderName}</h4>
                                                        <div className="user-message-box">
                                                            <p className="user-message">{user.senderMessage}</p>
                                                            <ul className="user-response-menu">
                                                                {/* Menu items */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="receiver">
                                                    <div className="user-img">
                                                        <div className="user-letter">
                                                            {user.receiverLogo} {/* Display the letter here */}
                                                        </div>
                                                    </div>
                                                    <div className="user-text">
                                                        <h4 className="user-name">{user.receiverName}</h4>
                                                        <div className="user-message-box">
                                                            <p className="user-message">
                                                                {user.receiverMessage}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {isTyping && (
                                        <div className="sender typing">
                                            <div className="user-img">
                                                <img src={senderImg} alt="senderImg" />
                                            </div>
                                            <div className="user-text">
                                                <h4 className="user-name">SAM-ML</h4>
                                                <div className="user-message-box">
                                                    <p className="user-message"></p> {/* Typing animation handled by CSS */}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="send-message-sec">
                                    <div className="send-message">
                                        <input
                                            type="text"
                                            name="message"
                                            id="message"
                                            placeholder='Chat with AI...'
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                            disabled={isTyping}
                                        />
                                        <div className="send-icon">
                                            <button onClick={handleSendMessage} className={`send-button ${message.trim() !== '' && !isTyping ? 'active' : ''}`}>
                                                <img src={sendarrow} alt="sendarrow" />
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Performance;
