import React, { useState, useRef, useEffect, useCallback } from 'react';
import './Management.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrashAlt, faCheck, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import dropdown from '../../assets/images/down-arrow.svg';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { post, isCancelError } from '@aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { Oval } from 'react-loader-spinner';
import { Treebeard, decorators } from 'react-treebeard';

const CustomHeader = ({ node, style, handleDelete = () => {}, handleClick = () => {} }) => {
    const handleClickWrapper = () => {
        if (node.isChild) {
            handleClick(node);
        }
    };

    return (
        <div style={style.base} onClick={handleClickWrapper}>
            <div style={style.title}>
                {node.name}
            </div>
        </div>
    );
};

const customDecorators = (handleDelete, handleClick) => ({
    ...decorators,
    Header: (props) => <CustomHeader {...props} handleDelete={handleDelete} handleClick={handleClick} />
});

const Management = () => {
    const [keys, setKeys] = useState([]);
    const [ids, setIds] = useState([]);
    const [names, setNames] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const storageRef = useRef(null);
    const [processType, setProcessType] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [companyUUID, setCompanyUUID] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [cursor, setCursor] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [treeData, setTreeData] = useState({
        name: 'Files',
        toggled: true,
        children: [
            {
                name: 'Past Performance',
                loading: true,
                children: []
            },
            {
                name: 'Past Contracts',
                loading: true,
                children: []
            }
        ]
    });

    useEffect(() => {
        async function getUserAttributes() {
            try {
                const user = await fetchUserAttributes();
                setCompanyUUID(user['custom:Company_uuidd']);
            } catch (error) {
                console.log('Error fetching user attributes', error);
            }
        }
        getUserAttributes();
    }, []);

    const pageload = useCallback(async () => {
        try {
            setSelectedFile(null);
            const fetchCompanyData = post({
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();

            const fetchDocData = post({
                apiName: 'DataFlow',
                path: '/DocManagement',
                options: {
                    body: {
                        process_id: '1',
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        task: 'Past Performance',
                        key: '',
                    },
                }
            });
            const response = await fetchDocData.response;
            const docData = await response.body.json();
            return docData;
        } catch (error) {
            if (isCancelError(error)) {
                console.log('Request cancelled');
            } else {
                console.error('Failed to fetch data:', error);
            }
        }
    }, [companyUUID]);

    useEffect(() => {
        if (!companyUUID) return;

        const parseData = async () => {
            try {
                setLoading(true);
                const responseData = await pageload();
                if (responseData && responseData.data && Array.isArray(responseData.data.document_name)) {
                    const formattedData = responseData.data.document_name.map((name, index) => {
                        const date = new Date(responseData.data.start_time[index]);
                        const formattedDate = date.toLocaleDateString();

                        return {
                            icon: <span className="file-icon"><FontAwesomeIcon icon={faFile} /></span>,
                            type: responseData.data.process[index],
                            name: name,
                            last_updated: formattedDate, 
                            status: responseData.data.complete[index],
                            key: responseData.data.key[index],
                            deleting: false,
                            isChild: true
                        };
                    });
                    setFilteredData(formattedData);

                    const pastPerformanceData = formattedData.filter(item => item.type === 'Past Performance');
                    const pastContractsData = formattedData.filter(item => item.type === 'Past Contract');

                    const newTreeData = {
                        name: 'Files',
                        toggled: true,
                        children: [
                            {
                                name: 'Past Performance',
                                children: pastPerformanceData
                            },
                            {
                                name: 'Past Contracts',
                                children: pastContractsData
                            }
                        ]
                    };

                    setTreeData(newTreeData);

                } else {
                    console.log('No data received or data is not in expected format');
                }
            } catch (error) {
                console.error('Error parsing data:', error);
            } finally {
                setLoading(false);
            }
        };

        parseData();
    }, [companyUUID, pageload]);

    const processFile = async ({ file }) => {
        const uuid = uuidv4();
      
        return file
          .arrayBuffer()
          .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
          .then((hashBuffer) => {
            return { file, key: `${uuid}_${file.name}` };
          });
      };


    const handleUploadSuccess = ({ key }) => {
        const fileKeyName = key.split('/').pop();
        const fileName = fileKeyName.split('_').pop();
        const fileKey = fileKeyName.split('_')[0];

        setIds(prev => [...prev, fileKey]);
        setNames(prev => [...prev, fileName]);
        setIsFileUploaded(true);
        setKeys(prev => [...prev, key]);
    };

    const handleDocumentTypeChange = (event) => {
        setProcessType(event.target.value);
        setShowWarning(false);
    };

    const handleUploadButtonClick = async () => {
        if (processType === 'Select Type' || processType === '') {
            setShowWarning(true);
            return;
        }

        setUploading(true);

        try {
            const fetchCompanyData = post({
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });



            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();

            const restOperation = post({
                apiName: 'DataFlow',
                path: '/DocRouting',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        keys: keys,
                        ids: ids,
                        file_names: names,
                        process_type: processType,
                        process_id: '1',
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                    },
                }
            });
            const response = await restOperation.response;

            if (response.statusCode === 200) {
                const uploadedFiles = keys.map((key, index) => ({
                    icon: <span className="file-icon"><FontAwesomeIcon icon={faFile} /></span>,
                    type: processType,
                    name: names[index],
                    last_updated: new Date().toISOString(),
                    status: 'processing',
                    key: key,
                    deleting: false
                }));

                setFilteredData(prevData => [...uploadedFiles, ...prevData]);
                setKeys([]);
                setIds([]);
                setNames([]);
                storageRef.current.clearFiles();
                setIsFileUploaded(false);
            } else {
                throw new Error('Failed to upload files');
            }

        } catch (error) {
            if (isCancelError(error)) {
                console.log('Request cancelled');
            } else {
                console.error('Failed to upload files:', error);
            }
        } finally {
            setUploading(false);
        }
    };

    const handleToggle = (node, toggled) => {
        if (cursor) {
            cursor.active = false;
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }
        setCursor(node);
        setTreeData({ ...treeData });
    };

    const [deleteIcon, setDeleteIcon] = useState(faTrashAlt);

    const handleDeleteClick = () => {
        if (deleteIcon === faTrashAlt) {
            setDeleteIcon(faCheck);
        } else if (deleteIcon === faCheck) {
            setDeleteIcon(faSpinner);
            handleDelete(selectedFile.key).then(() => {
                setDeleteIcon(faTrashAlt);
            });
        }
    };

    const handleDelete = async (fileKey) => {
        const newFilteredData = filteredData.map(item =>
            item.key === fileKey ? { ...item, deleting: true } : item
        );
        setFilteredData(newFilteredData);

        try {
            const fetchCompanyData = post({
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();

            const deleteResponse = post({
                apiName: 'DataFlow',
                path: '/DocManagement',
                options: {
                    body: {
                        process_id: uuidv4(),
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        start: 0,
                        finish: 0,
                        task: 'Delete',
                        key: fileKey,
                    },
                }
            });
            const response = await deleteResponse.response;
            if (response.statusCode === 200) {
                setFilteredData(currentData => currentData.filter(item => item.key !== fileKey));
                setSelectedFile(null);

                const updateTree = (node) => {
                    if (node.children) {
                        node.children = node.children.filter(child => child.key !== fileKey);
                        node.children.forEach(updateTree);
                    }
                };
                const newTreeData = { ...treeData };
                updateTree(newTreeData);
                setTreeData(newTreeData);

            } else {
                throw new Error('Deletion failed at the backend.');
            }
        } catch (error) {
            console.error('Failed to delete:', error);
            setFilteredData(currentData =>
                currentData.map(item =>
                    item.key === fileKey ? { ...item, deleting: false } : item
                )
            );
        }
    };

    const handleFileClick = (node) => {
        setSelectedFile(node);
    };

    const handleCancelClick = () => {
        setDeleteIcon(faTrashAlt);
    };

    const customStyles = {
        tree: {
            base: {
                listStyle: 'none',
                backgroundColor: '#d8d8d8',
                margin: 0,
                padding: 0,
                color: '#000',
                fontFamily: 'Lato, sans-serif',
                fontSize: '14px',
                height: '500px',
                overflowY: 'auto',
            },
            node: {
                base: {
                    position: 'relative',
                },
                link: {
                    cursor: 'pointer',
                    position: 'relative',
                    padding: '0px 5px',
                    display: 'block',
                },
                activeLink: {
                    background: '#ddd',
                },
                toggle: {
                    base: {
                        position: 'relative',
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginLeft: '-5px',
                        height: '24px',
                        width: '24px',
                    },
                    wrapper: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        margin: '-7px 0 0 -7px',
                        height: '14px',
                        width: '14px',
                        display: 'inline-block',
                    },
                    height: 14,
                    width: 14,
                    arrow: {
                        fill: '#9DA5AB',
                        strokeWidth: 0,
                    },
                },
                header: {
                    base: {
                        display: 'inline-block',
                        verticalAlign: 'top',
                        color: '#333',
                    },
                    connector: {
                        width: '2px',
                        height: '12px',
                        borderLeft: 'solid 2px black',
                        borderBottom: 'solid 2px black',
                        position: 'absolute',
                        top: '0px',
                        left: '-21px',
                    },
                    title: {
                        lineHeight: '24px',
                        verticalAlign: 'middle',
                    },
                },
                subtree: {
                    listStyle: 'none',
                    paddingLeft: '19px',
                },
                loading: {
                    color: '#E2C089',
                },
            },
        },
    };

    return (
        <div className="management-screen">
            <div className="container-fluid">
                <div className="main-title">
                    <h1>Document Management</h1>
                </div>
                {loading ? (
                    <>
                        <div className="table-header">
                            <div className="absolute-selection">
                                <div className="navigation-selection">
                                </div>
                            </div>
                            <div className="upload-control">
                                <div className="document-type">
                                </div>
                                <div className="upload-button">
                                </div>
                            </div>
                        </div>
                        <div className="table-section">
                            <div className="DataTable-class loader-container">
                                <Oval color="#00BFFF" height={80} width={80} />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="content-section">
                        <div className="tree-upload-container">
                            <div className="tree-section">
                                <Treebeard
                                    data={treeData}
                                    onToggle={handleToggle}
                                    style={customStyles}
                                    decorators={customDecorators(handleDelete, handleFileClick)}
                                />
                            </div>
                            <div className="upload-section">
                                <StorageManager
                                    acceptedFileTypes={['application/pdf', 'image/*']}
                                    path="public/"
                                    maxFileCount={5}
                                    isResumable
                                    processFile={processFile}
                                    onUploadSuccess={handleUploadSuccess}
                                    ref={storageRef}
                                    className="storage-manager"
                                />
                                <div className="upload-controls">
                                    <div className="document-type">
                                        <select name="documents" id="documents" className='selection' value={processType} onChange={handleDocumentTypeChange}>
                                            <option value="Select Type">Select Type</option>
                                            <option value="Past Performance">Past Performance</option>
                                            <option value="Past Contract">Past Contract</option>
                                        </select>
                                        <img src={dropdown} alt="dropdown" />
                                        {showWarning && <div style={{ color: 'red' }}>Please select a document type.</div>}
                                    </div>
                                    <div className="upload-button">
                                        <button className="btn" onClick={handleUploadButtonClick} disabled={processType === 'Select Type' || processType === '' || uploading || !isFileUploaded}>
                                            {uploading ? <div><Oval color="#FFFFFF" height={20} width={20} /></div> : 'Process Files'}
                                        </button>
                                    </div>
                                </div>
                                <div className="upload-controls">
                                    <div className="Info_box">
                                        {selectedFile && (
                                            <div className="file-info">
                                                <p><strong>File Name:</strong> {selectedFile.name}</p>
                                                <p><strong>Status:</strong> {selectedFile.status}</p>
                                                <p><strong>Last Updated:</strong> {selectedFile.last_updated}</p>
                                                <div className="delete-icons">
                                                    {deleteIcon === faTrashAlt ? (
                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={handleDeleteClick} className="small-icon" />
                                                    ) : (
                                                        <>
                                                            {deleteIcon === faSpinner ? (
                                                                <FontAwesomeIcon icon={faSpinner} spin className="small-icon" />
                                                            ) : (
                                                                <>
                                                                    <FontAwesomeIcon icon={faCheck} onClick={handleDeleteClick} className="small-icon" />
                                                                    <FontAwesomeIcon icon={faTimes} onClick={handleCancelClick} className="small-icon" />
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
};

export default Management;
