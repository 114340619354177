import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faChartBar, faLink } from '@fortawesome/free-solid-svg-icons';
import CustomPagination from '../../Components/CustomPagination/CustomPagination';
import './Dashboard.scss';
import ViolinChart from '../../Components/ViolinChart/ViolinChart';
import HistogramChart from '../../Components/HistogramChart/HistogramChart';
import BarChart from '../../Components/BarChart/BarChart';
import { post, isCancelError } from '@aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { Oval } from 'react-loader-spinner';
import { Modal, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';

const Dashboard = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [ViolinChartData, setViolinChartData] = useState([]);
    const [ViolinChartPastData, setViolinChartPastData] = useState([]);
    const [companyUUID, setCompanyUUID] = useState(null);
    const itemsPerPage = 10;
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalChartData, setModalChartData] = useState(null);
    const [modalBarData, setModalBarData] = useState(null);
    const columns = [
        {
            name: 'Rank',
            selector: row => row.pos,
            $grow: 0,
        },
        {
            name: 'Opportunity Type',
            selector: row => row.type,
            $grow: 1,
        },
        {
            name: 'Deadline',
            selector: row => row.dead,
            $grow: 1,
        },
        {
            name: 'Title',
            selector: row => row.title,
            $grow: 5,
        },
        {
            name: 'Mean Distance',
            selector: row => row.dist,
            $grow: 1,
        },
        {
            name:'Actions',
            cell: row => (
                <div className="action-buttons-dash">
                    <button className="btn link-btn" onClick={() => window.open(row.link, '_blank')}>
                        <FontAwesomeIcon icon={faLink} />
                    </button>

                    <button className="btn download-btn" onClick={() => handleShowModal(row)}>
                        <FontAwesomeIcon icon={faChartBar} />
                    </button>
                </div>
            ),
        },
    ];

    

    const handleShowModal = async (row) => {
        setModalData(row);
        setShowModal(true);
    
        try {
            setModalChartData(null);
            setModalBarData(null);
            const detailedData = await fetchDetailedData(row.vector);
            setModalChartData(detailedData.similarities);
            setModalBarData(detailedData);
        } catch (error) {
            console.error('Failed to fetch detailed data:', error);
        }
    };

    

    const fetchDetailedData = async (vector) => {
        try {
            const fetchCompanyData = post({ 
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();

            const DetailResponse = post({ 
                apiName: 'DataFlow',
                path: '/SAMDetailed',
                options: {
                    body: {
                        process_id: uuidv4(),
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        Vector: vector
                    },
                }
            });
            
            const response = await DetailResponse.response;
            const responsefinal = await response.body.json();
            return responsefinal.data
        } catch (error) {
            console.error('Error fetching detailed data:', error);
            return null;
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData(null);
    };

    

    useEffect(() => {
        async function getUserAttributes() {
            try {
                const user = await fetchUserAttributes();
                setCompanyUUID(user['custom:Company_uuidd']);
            } catch (error) {
                console.log('Error fetching user attributes', error);
            }
        }
        getUserAttributes();
    }, []);

    useEffect(() => {
        if (!companyUUID) return;

        const fetchApiData = async () => {
            try {
                const restOperation = post({ 
                    apiName: 'DataFlow',
                    path: '/SAMFinder',
                    options: {
                        body: {
                            company_uuid: companyUUID,
                            process_id: 'your_process_id_here',
                            requested_date: '2024-02-05'
                        }
                    }
                });
                const response = await restOperation.response;
                
                return response;
            } catch (error) {
                if (isCancelError(error)) {
                    console.log('Request cancelled');
                } else {
                    console.error('Failed to fetch data:', error);
                }
            }
        };
        const parseData = async () => {
            try {
                const response = await fetchApiData();
                if (response) {
                    const responseData = await response.body.json();
        
                    if (responseData && responseData.data && Array.isArray(responseData.data.titles)) {
                        const formattedData = responseData.data.titles.map((title, index) => ({
                            icon: <span className="file-icon"><FontAwesomeIcon icon={faFile} /></span>,
                            pos:index+1,
                            type: responseData.data.types[index],
                            dead: responseData.data.response_deadlines[index].split('T')[0],
                            title: title,
                            dist:parseFloat(responseData.data.average[index].toFixed(3)),
                            link: responseData.data.ui_links[index],
                            // <a href={responseData.data.ui_links[index]} target="_blank" rel="noopener noreferrer">Link</a>,
                            vector: responseData.data.vectors[index]
                        }));


                        const formattedDataForViolin = responseData.data.titles.map((title, index) => ({
                            pos:index+1,
                            type: responseData.data.types[index],
                            q1: responseData.data.q1[index],
                            q2: responseData.data.q2[index],
                            q3: responseData.data.q3[index],
                            average: responseData.data.average[index],
                            stedv: responseData.data.stedv[index],
                        }));
                        setViolinChartPastData([responseData['data']['past_ave'],responseData['data']['past_stdev']])
                        setViolinChartData(formattedDataForViolin)
                        setFilteredData(formattedData);
                    } else {
                        console.log('No data received or data is not in expected format');
                    }
                } else {
                    console.log('No response received');
                }
            } catch (error) {
                console.error('Error parsing data:', error);
            } finally {
                setLoading(false);
            }
        };
        parseData();
    }, [companyUUID]);

    const onPageChange = page => {
        setCurrentPage(page);
        // console.log("Current page set to:", page);
    };

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        
        return filteredData.slice(startIndex, endIndex);
    };

    const getPaginatedViolinData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        // console.log(ViolinChartData.slice(startIndex, endIndex))
        return ViolinChartData.slice(startIndex, endIndex);
    };

    return (
        <div className="dashboard-screen">
            <div className="container-fluid">
                <div className="main-title">
                    <h1>Dashboard</h1>
                </div>
                {loading ? (
                    <div className="charts-section">
                        <div className="row">
                            <div className="col-12">
                                <div className="chart-box">
                                    <div className="loader-container">
                                        <Oval color="#00BFFF" height={80} width={80} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="charts-section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="chart-box">
                                        <ViolinChart data={getPaginatedViolinData()} past={ViolinChartPastData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-section">
                            <DataTable
                                className={'DataTable-class'}
                                columns={columns}
                                data={getPaginatedData()}
                                pagination
                                paginationComponent={(props) => (
                                    <CustomPagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(filteredData.length / itemsPerPage)}
                                        onPageChange={onPageChange}
                                        {...props}
                                    />
                                )}
                                search={false}
                            />
                        </div>
                    </>
                )}
            </div>
            {modalData && (
                <Modal show={showModal} onHide={handleCloseModal} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Opportunity Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalChartData ? (
                            <div>
                                <HistogramChart similarities={modalChartData} verticalLineLocation={ViolinChartPastData[0]}/>
                                <BarChart data={modalBarData} />
                            </div>
                            // <div>HI</div>
                            // <ViolinChart data={[]} />
                        ) : (
                            <div>Loading...</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default Dashboard;
