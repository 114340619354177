import React, { useState, useRef, useEffect, useCallback } from 'react';
import './Opportunity.scss';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrashAlt, faDownload, faMagnifyingGlass, faChartBar } from '@fortawesome/free-solid-svg-icons';
import CustomPagination from '../../Components/CustomPagination/CustomPagination';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { post, isCancelError } from '@aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { Oval } from 'react-loader-spinner';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Scatterplot from '../../Components/Scatterplot/InDepthScatterplot';;

const Opportunity = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [keys, setKeys] = useState([]);
    const [ids, setIds] = useState([]);
    const [names, setNames] = useState([]);
    const [companyUUID, setCompanyUUID] = useState(null);
    const [loading, setLoading] = useState(true);
    const storageRef = useRef(null);
    const itemsPerPage = 10;
    const [parsing, setParsing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalTableData, setModalTableData] = useState([]);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalDownload, setModalDownload] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);


    const columns = [
        {
            name: '', 
            selector: row => row.icon,
            width: '60px',
        },
        {
            name: 'Document Name',
            selector: row => row.name,
        },
        {
            name: 'Date',
            selector: row => row.date,
        },
        {
            name: 'Status',
            selector: row => row.status,
        },
        {
            name: 'Progress',
            cell: row => (
                <div className="progress">
                    <div 
                        className="progress-bar" 
                        role="progressbar" 
                        style={{ width: `${row.percentage}%` }} 
                        aria-valuenow={row.percentage} 
                        aria-valuemin="0" 
                        aria-valuemax="100">
                    </div>
                </div>
            ),
            width: '200px', // or any other value you want
        },
        {
            name: 'Actions',
            width: '160px',
            cell: row => (
                <div className="action-buttons">
                    {row.deleting ? (
                        <div className="loading-icon">
                            <Oval color="#000000" height={20} width={20} />
                        </div>
                    ) : (
                        <>
                            {row.status === 'complete' && (
                                <>
                                    <button className="btn evaluate-btn" onClick={() => handleShowModal(row,'table')}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    </button>
                                    <button className="btn download-btn" onClick={() => handleShowModal(row, 'chart')}>
                                        <FontAwesomeIcon icon={faChartBar} />
                                    </button>
                                </>
                            )}
                            <button className="btn delete-btn" onClick={() => handleDelete(row.key)}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                        </>
                    )}
                </div>
            ),
        },
    ];

    const handleShowModal = async (row, type) => {
        setModalDownload(false)
        setModalData(row);
        setShowModal(true);
        setModalTableData([]);
        setModalTitle(row.name);
        setModalType(type);
        
    
        try {
            let detailedData;
            if (type === 'table') {
                detailedData = await fetchTableData(row.key);
                setModalDownload(true);
            } else {
                detailedData = await fetchChartData(row.key);
                setModalDownload(false);
            }
            setModalTableData(detailedData);
            
        } catch (error) {
            console.error('Failed to fetch detailed data:', error);
            setModalTableData([]);
        }
    };
    

    const fetchTableData = async (fileKey) => {
        try {
            const fetchCompanyData = post({ 
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
    
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();
    
            const restOperation = post({ 
                apiName: 'DataFlow',
                path: '/DocManagement',
                options: {
                    body: {
                        process_id: uuidv4(),
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        start: 0,
                        finish: 0,
                        task: 'Retrieve',
                        key: fileKey,
                    },
                }
            });
            const response = await restOperation.response;
            const docData = await response.body.json();
            return docData.data[0];
        } catch (error) {
            console.error('Error fetching detailed data:', error);
            return null;
        }
    };
    

    const fetchChartData = async (fileKey) => {
        try {
            const fetchCompanyData = post({ 
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
    
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();
    
            const restOperation = post({ 
                apiName: 'DataFlow',
                path: '/Indepth',
                options: {
                    body: {
                        process_id: uuidv4(),
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        start: 0,
                        finish: 0,
                        task: 'Retrieve',
                        key: fileKey,
                    },
                }
            });
            const response = await restOperation.response;
            const docData = await response.body.json();
            return docData;
        } catch (error) {
            console.error('Error fetching detailed data:', error);
            return null;
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData(null);
    };

    useEffect(() => {
        async function getUserAttributes() {
            try {
                const user = await fetchUserAttributes();
                setCompanyUUID(user['custom:Company_uuidd']);
            } catch (error) {
                console.log('Error fetching user attributes', error);
            }
        }
        getUserAttributes();
    }, []);

    const pageload = useCallback(async (start, finish) => {
        try {
            const fetchCompanyData = post({ 
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();

            const fetchDocData = post({ 
                apiName: 'DataFlow',
                path: '/DocManagement',
                options: {
                    body: {
                        process_id: uuidv4(),
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        start: start,
                        finish: finish,
                        task: 'PWS Parse',
                        key: '',
                    },
                }
            });
            const response = await fetchDocData.response;
            const docData = await response.body.json();
            return docData;
        } catch (error) {
            if (isCancelError(error)) {
                console.log('Request cancelled');
            } else {
                console.error('Failed to fetch data:', error);
            }
        }
    }, [companyUUID]);

    useEffect(() => {
        if (!companyUUID) return;
    
        const parseData = async (page) => {
            const startIndex = (page - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
    
            try {
                setLoading(true);
                const responseData = await pageload(startIndex, endIndex);
                if (responseData && responseData.data && Array.isArray(responseData.data.document_name)) {
                    const formattedData = responseData.data.document_name.map((name, index) => {
                        const utcDate = responseData.data.start_time[index];
                        const localDate = new Date(utcDate).toLocaleString();
    
                        return {
                            icon: <span className="file-icon"><FontAwesomeIcon icon={faFile} /></span>,
                            type: responseData.data.process[index],
                            name: name,
                            date: localDate,
                            status: responseData.data.complete[index],
                            key: responseData.data.key[index],
                            percentage: responseData.data.percentage[index],
                            deleting: false,
                        };
                    });
                    setFilteredData(formattedData);
                } else {
                    console.log('No data received or data is not in expected format');
                }
            } catch (error) {
                console.error('Error parsing data:', error);
            } finally {
                setLoading(false);
            }
        };
    
        parseData(currentPage);
    }, [companyUUID, currentPage, pageload]);


    

    const onPageChange = page => {
        setCurrentPage(page);
    };

    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredData.slice(startIndex, endIndex);
    };


    const processFile = async ({ file }) => {
        const uuid = uuidv4();
      
        return file
          .arrayBuffer()
          .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
          .then((hashBuffer) => {
            return { file, key: `${uuid}_${file.name}` };
          });
      };


    const handleUploadSuccess = ({ key }) => {
        const fileKeyName = key.split('/').pop();
        const fileName = fileKeyName.split('_').pop();
        const fileKey = fileKeyName.split('_')[0];

        setIds(prev => [...prev, fileKey]);
        setNames(prev => [...prev, fileName]);
        setIsFileUploaded(true);
        setKeys(prev => [...prev, key]);
    };

    const handleUploadButtonClick = async () => {
        setParsing(true); 
        try {
            const fetchCompanyData = post({ 
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
    
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();
    
            const restOperation = post({ 
                apiName: 'DataFlow',
                path: '/DocRouting',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        keys: keys,
                        ids: ids,
                        file_names: names,
                        process_type: 'PWS Parse',
                        process_id: '1',
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                    },
                }
            });
            const response = await restOperation.response;
            if (response.statusCode === 200) {
                const uploadedFiles = keys.map((key, index) => ({
                    icon: <span className="file-icon"><FontAwesomeIcon icon={faFile} /></span>,
                    type: 'PWS',
                    name: names[index],
                    last_updated: new Date().toISOString(),
                    status: 'processing',
                    key: key,
                    deleting: false,
                    percentage: 0
                }));
                
                setParsing(false);
                setFilteredData(prevData => [...uploadedFiles, ...prevData]);
                setKeys([]);
                setIds([]);
                setNames([]);
                storageRef.current.clearFiles();
            } else {
                throw new Error('Failed to upload files');
            }
    
        } catch (error) {
            if (isCancelError(error)) {
                console.log('Request cancelled');
            } else {
                console.error('Failed to upload files:', error);
            }
        } finally {
            setParsing(false);
        }
    };


    const handleFileRemove = ({ key }) => {
        const fileName = key.split('/').pop();
        const indexToRemove = names.findIndex(name => name === fileName);
        
        if (indexToRemove !== -1) {
            setIds(prev => prev.filter((_, index) => index !== indexToRemove));
            setNames(prev => prev.filter((_, index) => index !== indexToRemove));
            setKeys(prev => prev.filter((_, index) => index !== indexToRemove));

            if (ids.length === 1) {
                setIsFileUploaded(false);
            }
        }


    };
    
    const handleDelete = async (fileKey) => {

        const newFilteredData = filteredData.map(item =>
            item.key === fileKey ? { ...item, deleting: true } : item
        );
        setFilteredData(newFilteredData);

        try {
            const fetchCompanyData = post({ 
                apiName: 'DataFlow',
                path: '/ReturnInfo',
                options: {
                    body: {
                        company_uuid: companyUUID,
                        payload: '',
                        process_id: '1',
                        presigned: 'no',
                        keys: 'filekeys'
                    },
                }
            });
            const companyResponse = await fetchCompanyData.response;
            const companyData = await companyResponse.body.json();

            const deleteResponse = post({ 
                apiName: 'DataFlow',
                path: '/DocManagement',
                options: {
                    body: {
                        process_id: uuidv4(),
                        Endpoint: companyData.endpoint,
                        Region: companyData.region,
                        Role_ARN: companyData.role_arn,
                        Secret_ID: companyData.secret_id,
                        start: 0,
                        finish: 0,
                        task: 'Delete',
                        key: fileKey,
                    },
                }
            });
            const response = await deleteResponse.response;
            if (response.statusCode === 200) {
                setFilteredData(currentData => currentData.filter(item => item.key !== fileKey));
            } else {
                throw new Error('Deletion failed at the backend.');
            }
        } catch (error) {
            console.error('Failed to delete:', error);
            setFilteredData(currentData =>
                currentData.map(item =>
                    item.key === fileKey ? { ...item, deleting: false } : item
                )
            );
        }
    };

    const convertToCSV = (objArray) => {
        if (objArray.length === 0) {
            return '';
        }
    
        // Define headers based on the modal table structure
        const headers = ['Page Num', 'Title', 'Text', 'Relevant Segments', 'Document Information'];
        let csvString = headers.join(',') + '\r\n';
    
        objArray.forEach(item => {
            const row = [
                formatNumber(item["Page-Num"]), 
                item.Title,      
                `"${item.Text.replace(/"/g, '""')}"`, 
                `"${formatListWithNumbers(item.Relevant_Segements)}"`, 
                `"${formatListWithNumbers(item.Document_Information.map(doc => doc.name))}"` 
            ];
            csvString += row.join(',') + '\r\n';
        });
    
        return csvString;
    };
    
    const formatNumber = (num) => {
        return num.toLocaleString(); 
    };
    
    const formatListWithNumbers = (list) => {
        return list.map((item, index) => `${index + 1}. ${item}`).join('\n').replace(/"/g, '""');
    };
    
    const downloadCSV = (csvString, filename = 'data.csv') => {
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
    };
    
    const handleDownload = () => {
        try {
            if (modalTableData && modalTableData.length > 0) {
                const csvData = convertToCSV(modalTableData);
                downloadCSV(csvData, 'modal_data.csv');
            } else {
                console.error('No data to download');
            }
        } catch (error) {
            console.error('Failed to process data for download:', error);
        }
    };

    
    return (
        <div className="opportunity-screen">
            <div className="container-fluid">
                <div className="main-title">
                    <h1>Opportunity Parser</h1>
                </div>
                {loading ? (
                    <>
                        {/* <div className="charts-section" style={{ marginBottom: '20px' }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <StorageManager
                                        acceptedFileTypes={['application/pdf', 'image/*']}
                                        path="public/"
                                        maxFileCount={5}
                                        isResumable
                                        processFile={processFile}
                                        onUploadSuccess={handleUploadSuccess}
                                        ref={storageRef}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="table-header">
                            <div className="absolute-selection">
                                <div className="navigation-selection">
                                </div>
                            </div>
                            <div className="upload-control">
                                <div className="document-type">

                                </div>
                                <div className="upload-button">
                                   
                                </div>
                            </div>
                        </div>
                        <div className="table-section">
                            <div className="DataTable-class loader-container">
                                <Oval color="#00BFFF" height={80} width={80} />
                            </div>
                        </div>
                    </>
                
                ) : (
                    <>
                        <div className="charts-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <StorageManager
                                        acceptedFileTypes={['application/pdf', 'image/*']}
                                        path="public/"
                                        maxFileCount={5}
                                        isResumable
                                        processFile={processFile}
                                        onUploadSuccess={handleUploadSuccess}
                                        ref={storageRef}
                                        onFileRemove={handleFileRemove}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="table-header">
                            <div className="upload-control">
                                <div className="upload-button">
                                <button className="btn" onClick={handleUploadButtonClick} disabled={parsing || !isFileUploaded}>
                                    {parsing ? <Oval color="#FFFFFF" height={20} width={20} /> : 'Parse Files'}
                                </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-section">
                            <DataTable
                                className={'DataTable-class'}
                                columns={columns}
                                data={getPaginatedData()}
                                pagination
                                paginationComponent={(props) => (
                                    <CustomPagination
                                        currentPage={currentPage}
                                        totalPages={Math.ceil(filteredData.length / itemsPerPage)}
                                        onPageChange={onPageChange}
                                        {...props}
                                    />
                                )}
                                search={false}
                            />
                        </div>
                    </>
                )}
            </div>
            {modalData && (
                <Modal show={showModal} onHide={handleCloseModal} dialogClassName="wide-modal" size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {modalTitle}
                            {modalDownload && (
                                <button className="btn download-btn" onClick={() => handleDownload(modalTableData)} >
                                    <FontAwesomeIcon icon={faDownload} />
                                </button>
                            )}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalType === 'table' ? (
                            modalTableData && modalTableData.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Page Num</th>
                                            <th>Title</th>
                                            <th>Text</th>
                                            <th>Relevant Segments</th>
                                            <th>Document Information</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalTableData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item["Page-Num"]}</td>
                                                <td>{item.Title}</td>
                                                <td>{item.Text}</td>
                                                <td>
                                                    <ol>
                                                        {item.Relevant_Segements.map((segment, idx) => (
                                                            <li key={idx}>{segment}</li>
                                                        ))}
                                                    </ol>
                                                </td>
                                                <td>
                                                    <ol>
                                                        {item.Document_Information.map((doc, idx) => (
                                                            <li key={idx}>
                                                                {doc.name}
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div>Loading...</div>
                            )
                        ) : (
                            <Scatterplot data={modalTableData} />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

        </div>
    )
}

export default Opportunity;
